<template>
    <div class="edit-alert-page">
        <AlertMenu />
        <div v-if="!loaded">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <CreateEditAlert v-else  @alertChanged="handleChange" :is-create="false" :alert-data="alertData" />
        <Dialog modal :visible.sync="showWarningModal" :dismissableMask="true">
            <div class="dialog-content">
                <img src="/images/icons/exclamation.svg" alt="Warning">
                <h1 class="modal-title">
                    UNSAVED CHANGES
                </h1>
                <Divider />
                <p class="modal-content">
                    Exiting now will discard any changes made to your alert settings. Please save your changes to ensure your alert preferences are updated.
                </p>
                <div class="modal-buttons">
                    <Button class="btn black-bg" @click="exit">
                        Exit Anyway
                    </Button>
                    <Button class="btn orange-bg" @click="continueEdit">
                        Continue Editing
                    </Button>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { apiURL } from '../../util.js'
import AlertMenu from '../../components/AlertMenu.vue'
import CreateEditAlert from '../../components/CreateEditAlert.vue'
import Spinner from 'vue-simple-spinner';
import Dialog from 'primevue/dialog'
import Button from 'primevue/button';
import Divider from 'primevue/divider';
export default {
    name: "EditAlert",
    components: {
        AlertMenu,
        CreateEditAlert,
        Spinner,
        Dialog,
        Divider,
        Button
    },
    data(){
        return{
            apiURL,
            alertData: {},
            loaded: false,
            showWarningModal: false,
            changed: false,
        }
    },
    metaInfo: {
        title: 'Edit Your Alert',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
    async mounted(){
        this.alertData = await this.getAlertData();
        this.loaded = true;
    },
    beforeRouteLeave (to, from , next) {
        this.exit = () => {
            next();
        }
        this.continueEdit = () => {
            next(false);
            this.showWarningModal=false;
        }
        if(this.changed){
            this.showWarningModal = true;
        }else{
            next()
        }
    },
    methods: {
        async getAlertData() {
            try {
                const header = this.$store.getters.authHeader;
                const data = await this.axios.get(`${apiURL}/api/alert-manage/${this.$route.params.id}`,header);
                return data;
            } catch(e) {
                this.error = true;
            }
            return {};
        },
        exit(){
        },
        continueEdit(){

        },
        handleChange(changed){
            this.changed = changed;
        }
    }
}
</script>

<style lang="scss" scoped>
h1{
    text-align: center;
}
.input-container{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    label{
        width: 30%;
        text-align: right;
        margin-right: 1rem;
    }
    .input{
        width: 300px;
        max-width: 100%;
    }
}

.password-requirements{
    margin-left: calc(30% + 16px);
    padding-left: unset;
    list-style-type: none;
    opacity: 0.5;
}

.button{
    display: flex;
    justify-content: center;
    margin-top: 30px
}

::v-deep .formulate-input[data-classification=button] button{
    border-color: #EF6A40;
    background: #EF6A40;
}

@media (max-width: $mobile) {
    .container{
        flex-wrap: wrap;
    }
}
</style>